import {
  ASSISTED_LIVING,
  MEMORY_CARE,
  INDEPENDENT_LIVING,
  BOARD_AND_CARE_HOME,
  SKILLED_NURSING_FACILITY,
  CONTINUING_CARE_RETIREMENT_COMMUNITY,
  ACTIVE_ADULT
} from './tags';
import { AmenitiesAndServiceType } from './../api/types/amenities';

export const healthCareService = 'healthCareService';
export const roomAmenitiesType = 'roomAmenities';
export const communityAmenitiesType = 'communityAmenities';
export const communityServiceType = 'communityService';
export const diningServiceType = 'diningService';

export const amenitiesAndServiceTitles = {
  healthCareService: 'Health care services',
  roomAmenities: 'Room amenities',
  communityAmenities: 'Community amenities',
  communityService: 'Community services',
  diningService: 'Dining services'
};

export const PET_FRIENDLY = 'petFriendly';

export const amenitiesAndServiceTypes: Record<AmenitiesAndServiceType, any> = {
  healthCareService: healthCareService,
  roomAmenities: roomAmenitiesType,
  communityAmenities: communityAmenitiesType,
  communityService: communityServiceType,
  diningService: diningServiceType
};

export const amenitiesAndServiceItems: Record<string, any> = {
  [amenitiesAndServiceTypes.healthCareService]: {
    specializedMemoryCareProgram: {
      label: 'Specialized memory care programming',
      icon: 'Memory',
      excludeForFilter: true
    },
    hasMildCognitiveImpairment: {
      label: 'Mild cognitive impairment',
      icon: 'Memory'
    },
    mentalWellnessProgram: {
      label: 'Mental wellness program',
      icon: 'Memory',
      excludeForFilter: true
    },
    hasDementiaCare: {
      label: 'Dementia care',
      icon: 'Memory'
    },
    hasDementiaWaiver: {
      label: 'Dementia waiver',
      icon: 'Memory'
    },
    hasMedicationManagement: {
      label: 'Medication management',
      icon: 'Medication'
    },
    has24HourSupervision: {
      label: '24-hour supervision',
      icon: 'Time'
    },
    hasActivitiesOfDailyAssistance: {
      label: 'Activities of daily living assistance',
      icon: 'Care'
    },
    hasAssistanceBathing: {
      label: 'Assistance with bathing',
      icon: 'Bathing'
    },
    hasAssistanceDressing: {
      label: 'Assistance with dressing',
      icon: 'Clothes'
    },
    hasAssistanceTransfer: {
      label: 'Assistance with transfers',
      icon: 'Bed'
    },
    caregiversLanguageSpoken: {
      label: 'Caregivers languages spoken',
      icon: 'Language',
      excludeForFilter: true
    },
    hasDiabetesCare: {
      label: 'Diabetes care',
      icon: 'Care'
    },
    hasAdministerInsulin: {
      label: 'Administer insulin injections',
      icon: 'Vaccines'
    },
    hasIncontinenceCare: {
      label: 'Incontinence care',
      icon: 'Restroom'
    },
    hasNonAmbulatoryCare: {
      label: 'Non-ambulatory care',
      icon: 'Accessible'
    },
    hasParkinsonCare: {
      label: "Parkinson's care",
      icon: 'Care'
    },
    hasCareWithBehavioral: {
      label: 'Care with behavioral issues',
      icon: 'Memory'
    },
    hasHospiceWaiver: {
      label: 'Hospice waiver',
      icon: 'Contract'
    },
    hasRespiteProgram: {
      label: 'Respite program',
      icon: 'HomeHealth',
      excludeForFilter: true
    },
    has24HourCallSystem: {
      label: '24-hour call system',
      icon: 'Phone'
    },
    has12To16HourNursing: {
      label: '12-16 hour nursing',
      icon: 'Time'
    },
    has24HourNursing: {
      label: '24-hour nursing',
      icon: 'Time'
    },
    hasHearingAssistiveDevices: {
      label: 'Hearing assistive devices',
      icon: 'Hearing'
    },
    hasCoordinationWithHealthCareProviders: {
      label: 'Coordination with health care providers',
      icon: 'Care'
    },
    hasPreventiveHealthScreenings: {
      label: 'Preventive health screenings',
      icon: 'HealthSafety'
    },
    hasPhysicalTherapy: {
      label: 'Physical therapy',
      icon: 'Care'
    },
    rehabilitationProgram: {
      label: 'Rehabilitation program',
      icon: 'Care',
      excludeForFilter: true
    },
    caregiverCertifications: {
      label: 'Caregiver certifications',
      icon: 'About',
      excludeForFilter: true
    },
    caregiverNurseQualifications: {
      label: 'Caregiver nurse qualifications',
      icon: 'Safety',
      excludeForFilter: true
    },
    hasWheelChairAccessible: {
      label: 'Wheelchair accessible',
      icon: 'Accessible'
    },
    hasSafetyRails: {
      label: 'Safety rails',
      icon: 'HealthSafety'
    },
    hasPersonalEmergencyResponseDevice: {
      label: 'Personal emergency response device',
      icon: 'Emergency'
    },
    hasCognitiveLearningCenter: {
      label: 'Cognitive learning center',
      icon: 'Memory'
    },
    hasSmokeAlarmAndSprinklerSystem: {
      label: 'Smoke alarm and sprinkler system',
      icon: 'SmokeDetector'
    },
    hasBedBound: {
      label: 'Bed-bound',
      icon: 'Bed'
    },
    hasBloodSugarMonitoring: {
      label: 'Blood sugar monitoring',
      icon: 'Care'
    },
    hasHandOverHandPenInjections: {
      label: 'Hand-over-hand pen injections',
      icon: 'Care'
    },
    hasTwoPersonTransfer: {
      label: 'Two person transfers',
      icon: 'Care'
    },
    hasHoyerLift: {
      label: 'Hoyer lift',
      icon: 'Care'
    },
    hasCatheter: {
      label: 'Catheter',
      icon: 'Care'
    },
    hasColostomyBag: {
      label: 'Colostomy bag',
      icon: 'Care'
    },
    hasGTubes: {
      label: 'G-tubes',
      icon: 'Care'
    },
    hasWoundCare: {
      label: 'Wound care',
      icon: 'Care'
    }
  },
  [amenitiesAndServiceTypes.roomAmenities]: {
    hasPrivateBathroom: {
      label: 'Private bathroom',
      icon: 'Bathing'
    },
    hasKitchenette: {
      label: 'Kitchenette',
      icon: 'Kitchen'
    },
    hasAirConditioning: {
      label: 'Air conditioning',
      icon: 'Ac'
    },
    hasWifi: {
      label: 'Wi-Fi / high-speed internet',
      icon: 'Wifi'
    },
    hasCable: {
      label: 'Cable TV',
      icon: 'Tv'
    },
    hasTelephone: {
      label: 'Telephone',
      icon: 'Phone'
    },
    hasFurniture: {
      label: 'Furnished',
      icon: 'Chair'
    },
    hasWasherAndDryer: {
      label: 'Washer and dryer',
      icon: 'Washer'
    }
  },
  [amenitiesAndServiceTypes.communityAmenities]: {
    hasDayTrips: {
      label: 'Day trips',
      icon: 'Transportation'
    },
    hasOutdoorPrograms: {
      label: 'Outdoor programs',
      icon: 'Outdoors'
    },
    fitnessPrograms: {
      label: 'Fitness programs',
      icon: 'Fitness'
    },

    hasMovieNights: {
      label: 'Movie nights',
      icon: 'Movie'
    },
    hasResidentRunActivities: {
      label: 'Resident-run activities',
      icon: 'Activity'
    },
    hasScheduledDailyActivities: {
      label: 'Scheduled daily activities',
      icon: 'Activity'
    },
    communitySponsoredActivities: {
      label: 'Community-sponsored activities',
      icon: 'Family'
    },
    hasGameRoom: {
      label: 'Game room',
      icon: 'Games'
    },
    hasMovieRoom: {
      label: 'Movie theater',
      icon: 'Movie'
    },
    hasActivityRoom: {
      label: 'Activity room',
      icon: 'Activity'
    },
    hasArtsRoom: {
      label: 'Arts room',
      icon: 'Arts'
    },
    hasLibrary: {
      label: 'Library',
      icon: 'Book'
    },
    hasBusinessRoom: {
      label: 'Business room',
      icon: 'Business'
    },
    hasChapel: {
      label: 'Chapel',
      icon: 'Church'
    },
    hasFitnessRoom: {
      label: 'Fitness room',
      icon: 'Fitness'
    },
    hasSpaWellnessRoom: {
      label: 'Spa / sauna / wellness room',
      icon: 'Spa'
    },
    hasBarberSalon: {
      label: 'Barber / Salon',
      icon: 'Spa'
    },
    hasSwimmingPool: {
      label: 'Swimming pool / jacuzzi',
      icon: 'Pool'
    },
    hasTherapyRoom: {
      label: 'Therapy room',
      icon: 'Spa'
    },
    hasWalkingPaths: {
      label: 'Walking paths',
      icon: 'Walk'
    },
    hasGarden: {
      label: 'Garden',
      icon: 'Garden'
    },
    hasCourtyard: {
      label: 'Courtyard',
      icon: 'Deck'
    },
    petFriendly: {
      label: 'Pet-friendly',
      icon: 'Pets',
      excludeForFilter: true
    },
    hasEmergencyAlertSystem: {
      label: 'Emergency Alert System',
      icon: 'Emergency'
    },
    hasOnsiteMarket: {
      label: 'On-site market / Store',
      icon: 'Store'
    },
    hasFireplace: {
      label: 'Fireplace',
      icon: 'Fireplace'
    },
    hasElevators: {
      label: 'Elevators',
      icon: 'Elevator'
    },
    hasPiano: {
      label: 'Piano',
      icon: 'Music'
    },
    religiousAffinities: {
      label: 'Faith Based',
      icon: 'Religion',
      excludeForFilter: true
    },
    hasMusicProgram: {
      label: 'Music programs',
      icon: 'Music'
    },
    hasVideoTours: {
      label: 'Video tours offered',
      icon: 'Video'
    },
    hasOvernightVisitors: {
      label: 'Overnight visitors',
      icon: 'Night'
    },
    hasTransportationAndParking: {
      label: 'Transportation & parking',
      icon: 'Transportation'
    },
    hasGuestSuites: {
      label: 'Guest suites',
      icon: 'Bed'
    },
    hasLgbtqs: {
      label: 'LGBTQ inclusive',
      icon: 'Checkmark'
    },
    hasOnSitePhysicianOfficeSpace: {
      label: 'On-site physician office space',
      icon: 'Stethoscope'
    },
    hasLockedMailboxes: {
      label: 'Locked mailboxes',
      icon: 'Mail'
    },
    hasAutomaticOnlinePayments: {
      label: 'Automatic online payments',
      icon: 'Payment'
    },
    hasPatio: {
      label: 'Patio',
      icon: 'Deck'
    },
    hasOutdoorCommonSpace: {
      label: 'Outdoor common space',
      icon: 'Outdoors'
    },
    hasAmazonEchoShow: {
      label: 'Amazon Echo show',
      icon: 'Awesome'
    },
    hasTrashChutes: {
      label: 'Trash chutes',
      icon: 'Delete'
    },
    hasPuttingGreen: {
      label: 'Putting green',
      icon: 'Golf'
    },
    hasKoiPond: {
      label: 'Koi pond',
      icon: 'Water'
    },
    hasNewspaperDelivery: {
      label: 'Newspaper delivery',
      icon: 'Article'
    },
    hasCottages: {
      label: 'Cottages',
      icon: 'HomeSmallStroke'
    },
    hasStorageUnitsAvailable: {
      label: 'Storage units available',
      icon: 'Storage'
    },
    hasPoolsideClubhouse: {
      label: 'Poolside clubhouse',
      icon: 'HomeSmallStroke'
    },
    hasVolunteerPrograms: {
      label: 'Volunteer program',
      icon: 'BestCare'
    }
  },
  [amenitiesAndServiceTypes.communityService]: {
    hasHouseKeeping: {
      label: 'Housekeeping',
      icon: 'Housekeeping'
    },
    hasLaundry: {
      label: 'Laundry service / dry cleaning',
      icon: 'Washer'
    },
    hasFamilySupportServices: {
      label: 'Family support services',
      icon: 'Checkmark'
    },
    hasMoveInCoOrdination: {
      label: 'Move-in coordination',
      icon: 'Book'
    },
    hasConcierge: {
      label: 'Concierge',
      icon: 'Concierge'
    },
    hasContinuingLearningExperience: {
      label: 'Continuing learning experience',
      icon: 'Book'
    },
    hasPostalServices: {
      label: 'Postal services',
      icon: 'Mail'
    },
    hasCovidPolicy: {
      label: 'Covid policy',
      icon: 'Covid'
    },
    hasSameDayAssessments: {
      label: 'Same-day assessments',
      icon: 'Calendar'
    },
    hasPreventiveScreening: {
      label: 'Preventive screenings',
      icon: 'HealthSafety'
    },
    hasRentersLiabilityInsuranceProgram: {
      label: 'Renters liability insurance program',
      icon: 'Contract'
    },
    hasMostUtilitiesIncluded: {
      label: 'Most utilities included',
      icon: 'Lightbulb'
    },
    hasMaintenance: {
      label: 'Maintenance',
      icon: 'Construction'
    },
    hasGroceryShopping: {
      label: 'Grocery shopping / errands',
      icon: 'Checkmark'
    },
    acceptedFinancialServices: {
      label: 'Accepted financial services',
      icon: 'Money'
    }
  },
  [amenitiesAndServiceTypes.diningService]: {
    hasDiningRoom: {
      label: 'Dining room',
      icon: 'DiningRoom'
    },
    hasRestaurantStyleDining: {
      label: 'Restaurant-style dining',
      icon: 'Food'
    },
    hasCafe: {
      label: 'Cafe',
      icon: 'Cafe'
    },
    diningOptions: {
      label: 'Dining options',
      icon: 'Food',
      excludeForFilter: true
    },
    setMenu: {
      label: 'Set menu',
      icon: 'Food',
      excludeForFilter: true
    },
    choiceMenu: {
      label: 'Choice menu',
      icon: 'Food',
      excludeForFilter: true
    },
    mealPreparationAndServiceBool: {
      label: 'Meal preparation and service',
      icon: 'Food',
      excludeForFilter: true
    },
    allDayDining: {
      label: 'All day dining',
      icon: 'Food'
    },
    scheduledMeals: {
      label: 'Scheduled meals',
      icon: 'Food'
    },
    hasAllergySensitive: {
      label: 'Allergy-sensitive',
      icon: 'Food'
    },
    hasDiabetesDiet: {
      label: 'Diabetes diet',
      icon: 'Food'
    },
    hasVegetarian: {
      label: 'Vegetarian',
      icon: 'Food'
    },
    hasInternationalCuisine: {
      label: 'International cuisine',
      icon: 'Food'
    },
    specialDietaryRestrictions: {
      label: 'Special dietary restrictions',
      icon: 'Food'
    },
    menuHighlights: {
      label: 'Menu highlights',
      icon: 'Book',
      excludeForFilter: true
    },
    aboutTheChef: {
      label: 'About the chef',
      icon: 'About',
      excludeForFilter: true
    },
    hasProfessionalChef: {
      label: 'Professional chef',
      icon: 'Food'
    },
    hasSetMenu: {
      label: 'Set Menu',
      icon: 'Food'
    },
    hasChoiceMenu: {
      label: 'Choice Menu',
      icon: 'Food'
    },
    hasSelectionOfAlteredTextureOptions: {
      label: 'Selection of altered-texture options',
      icon: 'Food'
    },
    hasOutdoorDining: {
      label: 'Outdoor dining',
      icon: 'Deck'
    },
    hasGrill: {
      label: 'Grill',
      icon: 'Grill'
    },
    hasSportsOrCocktailLounge: {
      label: 'Sports / cocktail lounge',
      icon: 'Drink'
    },
    hasBistro: {
      label: 'Bistro',
      icon: 'Deck'
    },
    hasLocallyInspiredCuisine: {
      label: 'Locally-inspired cuisine',
      icon: 'Food'
    },
    hasIceCreamParlor: {
      label: 'Ice cream parlor',
      icon: 'IceCream'
    },
    hasPatioDining: {
      label: 'Patio dining',
      icon: 'Deck'
    },
    hasKosherMeals: {
      label: 'Kosher meals',
      icon: 'Food'
    }
  }
};

export const defaultAmenitiesAndService: Record<string, any> = {
  from51Beds: {
    [amenitiesAndServiceTypes.communityAmenities]: [
      'hasArtsRoom',
      'hasBusinessRoom',
      'hasTransportationAndParking',
      'hasActivityRoom',
      'fitnessPrograms',
      'hasFitnessRoom',
      'hasGameRoom',
      'hasLibrary',
      'hasMovieRoom',
      'hasMusicProgram',
      'hasOutdoorPrograms',
      'hasResidentRunActivities',
      'hasScheduledDailyActivities',
      'hasSpaWellnessRoom',
      'hasBarberSalon',
      'hasWalkingPaths',
      'hasOutdoorCommonSpace'
    ],
    [amenitiesAndServiceTypes.communityService]: [
      'hasConcierge',
      'hasFamilySupportServices'
    ],
    [amenitiesAndServiceTypes.diningService]: [
      'allDayDining',
      'hasProfessionalChef',
      'hasRestaurantStyleDining'
    ],
    [amenitiesAndServiceTypes.roomAmenities]: [
      'hasCable',
      'hasAirConditioning',
      'hasKitchenette',
      'hasPrivateBathroom',
      'hasWifi'
    ],
    [amenitiesAndServiceTypes.healthCareService]: ['has24HourCallSystem']
  },
  from20To51Beds: {
    [amenitiesAndServiceTypes.communityAmenities]: ['hasArtsRoom'],
    [amenitiesAndServiceTypes.diningService]: ['allDayDining'],
    [amenitiesAndServiceTypes.healthCareService]: ['has24HourCallSystem']
  },
  upTo20Beds: {
    [amenitiesAndServiceTypes.diningService]: ['scheduledMeals']
  },
  allCare: {
    [amenitiesAndServiceTypes.communityAmenities]: [
      'communitySponsoredActivities',
      'hasEmergencyAlertSystem',
      'hasMovieNights'
    ],
    [amenitiesAndServiceTypes.communityService]: [
      'hasHouseKeeping',
      'hasLaundry',
      'hasMoveInCoOrdination'
    ],
    [amenitiesAndServiceTypes.diningService]: [
      'hasAllergySensitive',
      'hasDiningRoom',
      'mealPreparationAndServiceBool',
      'hasDiabetesDiet',
      'specialDietaryRestrictions'
    ],
    [amenitiesAndServiceTypes.roomAmenities]: ['hasFurniture'],
    [amenitiesAndServiceTypes.healthCareService]: [
      'has24HourSupervision',
      'caregiversLanguageSpoken',
      'hasMedicationManagement',
      'hasNonAmbulatoryCare'
    ]
  },
  [ASSISTED_LIVING]: {
    [amenitiesAndServiceTypes.healthCareService]: [
      'hasAssistanceBathing',
      'hasAssistanceDressing',
      'hasAssistanceTransfer',
      'hasActivitiesOfDailyAssistance',
      'hasWheelChairAccessible'
    ]
  },
  [MEMORY_CARE]: {
    [amenitiesAndServiceTypes.healthCareService]: [
      'hasAssistanceBathing',
      'hasAssistanceDressing',
      'hasAssistanceTransfer',
      'hasActivitiesOfDailyAssistance',
      'hasDementiaCare',
      'hasDementiaWaiver',
      'mentalWellnessProgram',
      'hasMildCognitiveImpairment'
    ]
  },
  [CONTINUING_CARE_RETIREMENT_COMMUNITY]: {
    [amenitiesAndServiceTypes.healthCareService]: [
      'hasAssistanceBathing',
      'hasAssistanceDressing',
      'hasAssistanceTransfer',
      'hasActivitiesOfDailyAssistance'
    ]
  },
  [SKILLED_NURSING_FACILITY]: {
    [amenitiesAndServiceTypes.healthCareService]: [
      'hasAssistanceBathing',
      'hasAssistanceDressing',
      'hasAssistanceTransfer',
      'hasActivitiesOfDailyAssistance'
    ]
  },
  [ACTIVE_ADULT]: {
    [amenitiesAndServiceTypes.healthCareService]: [
      'hasAssistanceBathing',
      'hasAssistanceDressing',
      'hasAssistanceTransfer',
      'hasActivitiesOfDailyAssistance'
    ]
  },
  [BOARD_AND_CARE_HOME]: {
    [amenitiesAndServiceTypes.healthCareService]: [
      'hasAssistanceBathing',
      'hasAssistanceDressing',
      'hasAssistanceTransfer',
      'hasActivitiesOfDailyAssistance'
    ]
  },
  [INDEPENDENT_LIVING]: {}
};

export const keyAmenitiesAndService: Record<string, any> = {
  allCare: {
    [amenitiesAndServiceTypes.communityAmenities]: [
      'communitySponsoredActivities',
      'hasEmergencyAlertSystem',
      'petFriendly',
      'religiousAffinities'
    ],
    [amenitiesAndServiceTypes.communityService]: [
      'hasConcierge',
      'hasMoveInCoOrdination'
    ],
    [amenitiesAndServiceTypes.diningService]: [
      'setMenu',
      'choiceMenu',
      'mealPreparationAndServiceBool',
      'hasProfessionalChef',
      'specialDietaryRestrictions'
    ],
    [amenitiesAndServiceTypes.roomAmenities]: ['hasFurniture'],
    [amenitiesAndServiceTypes.healthCareService]: []
  },
  [INDEPENDENT_LIVING]: {
    [amenitiesAndServiceTypes.communityAmenities]: [
      'hasTransportationAndParking'
    ],
    [amenitiesAndServiceTypes.communityService]: [],
    [amenitiesAndServiceTypes.diningService]: [],
    [amenitiesAndServiceTypes.roomAmenities]: [],
    [amenitiesAndServiceTypes.healthCareService]: []
  }
};

export const amenitiesToExcludeFromDescription: { [key: string]: string[] } = {
  petFriendly: ['yes'],
  communitySponsoredActivities: ['yes'],
  fitnessPrograms: ['yes'],
  specialDietaryRestrictions: ['yes'],
  acceptedFinancialServices: ['hasOther']
};

export const hideAmenitiesList: { [key: string]: string[] } = {
  petFriendly: ['noPets']
};

export const AmenitiesKeyLabel = {
  hasResidentBand: 'Resident band or musicians',
  hasBridgeClub: 'Bridge club',
  hasBookClub: 'Book club',
  hasChoirSingingClub: 'Choir / singing club',
  hasBibleStudyGroup: 'Bible study group',
  hasCurrentEventsClub: 'Current events club',
  hasCardsClub: 'Cards / pinochle club',
  hasQuiltingClub: 'Quilting or sewing club',
  hasHappyHour: 'Happy hour',
  hasCookingClasses: 'Cooking classes',
  hasLiveDance: 'Live dance or theater performances',
  hasHolidayParties: 'Holiday parties',
  hasDances: 'Dances',
  hasArtClasses: 'Art classes',
  hasKaroake: 'Karaoke',
  hasTriviaGames: 'Trivia games',
  hasLiveWellPrograms: 'Live well programs',
  hasWaterAerobics: 'Water aerobics',
  hasWineTasting: 'Wine tasting',
  hasCookingClug: 'Cooking club',
  hasWalkingClub: 'Walking club',
  hasWoodWorkingShop: 'Woodworking shop',
  hasQiGong: 'Qi Gong',
  hasStretchingClasses: 'Stretching Classes',
  hasYoga: 'Yoga / Chair Yoga',
  hasTaiChi: 'Tai Chi',
  hasGroceryShopping: 'Grocery shopping / errands',
  hasPureedMeals: 'Pureed meals',
  hasLowSodium: 'Low / No Sodium',
  hasRN: 'RN',
  hasCNA: 'CNA',
  hasLVN: 'LVN',
  hasMedicaid: 'Medicaid',
  hasMedicare: 'Medicare',
  hasSocialSecurity: 'Social Security',
  hasVeteransBenefits: 'Veterans Benefits',
  hasAssistedLivingWaiver: 'Assisted Living Waiver (ALW)',
  hasOther: 'Other'
};
