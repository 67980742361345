export const ROOM_PREFERENCES_OPTIONS = [
  {
    label: 'Shared Suite',
    value: 'Shared Suite'
  },
  {
    label: 'Private Suite',
    value: 'Private Suite'
  },
  {
    label: 'Studio',
    value: 'Studio'
  },
  {
    label: '1 Bedroom',
    value: '1 Bedroom'
  },
  {
    label: '2 Bedroom',
    value: '2 Bedroom'
  },
  {
    label: 'Other',
    value: 'Other'
  }
];

export const FAMILY_INTERESTS_OPTIONS = [
  { label: 'Talking to family and friends', value: 'friends-family' },
  { label: 'Spiritual/faith based activity', value: 'faith-activity' },
  { label: 'Walk or physical activity', value: 'physical-activity' },
  { label: 'Being outdoors', value: 'outdoor-activity' },
  { label: 'A good meal', value: 'meal' },
  { label: 'TV, movies or music', value: 'tv' }
];

export const MENTAL_HEALTH_OPTIONS = [
  { label: 'Memory issues', value: 'memory' },
  { label: 'Hallucinations', value: 'hallucinations' },
  { label: 'Wandering', value: 'wandering' },
  { label: 'Aggressiveness towards others', value: 'aggression' },
  { label: 'Withdrawal from activities', value: 'withdrawal' },
  { label: 'Inappropriate behaviors', value: 'inappropriateness' },
  { label: 'None', value: 'none' }
];

export const MENTAL_HEALTH_DASHBOARD_ONLY_OPTIONS = [
  { label: 'Dementia', value: 'dementia' },
  {
    label: 'Mild cognitive impairment (MCI)',
    value: 'mild-cognitive-impairment'
  },
  { label: "Alzheimer's", value: 'alzheimers' },
  { label: 'Sundowning', value: 'sundowning' },
  { label: 'Combativeness', value: 'combativeness' },
  { label: "Doesn't sleep through night", value: 'restless-nights' }
];

const FAMILY_AGENT_FILTER_OPTIONS = [
  'resident',
  'stage',
  'phone',
  'email',
  'updated',
  'created',
  'community'
];

export const FAMILY_ADMIN_FILTER_OPTIONS = [
  ...FAMILY_AGENT_FILTER_OPTIONS,
  'status',
  'is-contract',
  'city',
  'state',
  'agent',
  'invoice-number',
  'invoice-paid'
];

export const FAMILY_CARE_NEEDS_OPTIONS = [
  { label: 'No care', value: 'no-care' },
  { label: 'Light care', value: 'light-care' },
  { label: 'Heavy care', value: 'heavy-care' },
  { label: 'Memory care', value: 'memory-care' }
];

export const FAMILY_CARE_NEEDS_DASHBOARD_ONLY_OPTIONS = [
  { label: 'Diabetes', value: 'diabetes' },
  { label: 'Insulin injections', value: 'insulin-injections' },
  { label: 'Sliding scale injections', value: 'sliding-scale-injections' },
  { label: 'Blood sugar monitoring', value: 'blood-sugar-monitoring' },
  { label: 'Soft pureed diet', value: 'soft-pureed-diet' },
  { label: 'Low sugar diet', value: 'low-sugar-diet' }
];

export const FAMILY_MOBILITY_DASHBOARD_ONLY_OPTIONS = [
  { label: 'Ambulatory', value: 'hasAmbulatory' },
  { label: 'Non ambulatory', value: 'hasNonAmbulatory' },
  { label: 'Cane', value: 'hasCane' },
  { label: 'Walker', value: 'hasWalker' },
  { label: 'Hoyer lift', value: 'hasHoyerLift' },
  {
    label: 'Wheelchair',
    value: 'hasWheelChair',
    additionalOptions: [
      { label: '1 person transfer', value: 'hasWheelChairOnePersonTransfer' },
      { label: '2 person transfer', value: 'hasWheelChairTwoPersonTransfer' }
    ]
  }
];
