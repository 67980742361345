export const COMMUNITY_ENTITY_TYPE = 'Community';
export const PROPERTY_ENTITY_TYPE = 'Property';
export const AGENT_ENTITY_TYPE = 'PartnerAgent';
export const CLIENT_ENTITY_TYPE = 'Client';
export const SLY_ENTITY_RESOURCE_TYPE = 'SlyEntity';

export const ENTITY_LABEL_MAP = {
  [PROPERTY_ENTITY_TYPE]: 'Property',
  [COMMUNITY_ENTITY_TYPE]: 'Community',
  [AGENT_ENTITY_TYPE]: 'Agent'
};

export const LISTING_ENTITY_TYPE = 'Listing';
