/** @jsxImportSource react */
import { getClasses } from '@common/helpers/button';
import { eventNames, useEvents } from '@react/services/events';
import {
  ButtonProps,
  GetButtonPropsType,
} from '@react/system/Button/button.types';
import cx from 'classnames';
import React, { forwardRef } from 'react';
// todo: most probably should be common in future

const getTarget = (href: string) => {
  if (!href.match(/https?:\/\//)) {
    return {};
  }

  return {
    target: '_blank',
    rel: 'noopener',
  };
};

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      to,
      href: hrefProps,
      event,
      onClick: onClickProp,
      eventProps = {},
      className,
      as = 'button',
      disabled = false,
      variant = 'primary',
      palette = 'viridian',
      ...props
    },
    ref
  ) => {
    const { events } = useEvents();

    const getProps = () => {
      const onClick = (...args: any[]) => {
        if (event || eventProps) {
          if (typeof event !== 'object') {
            if (to || hrefProps) {
              eventProps.href = to || hrefProps;
            }

            const eventName =
              typeof event === 'string' ? event : eventNames.ButtonClicked;
            events.track(eventName, {
              text: typeof props?.children === 'string' ? props.children : null,
              ...eventProps,
            });
          } else {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            events.track(event);
          }
        }
        if (onClickProp) {
          return onClickProp(...args);
        }
        return null;
      };

      const href = to || hrefProps;

      if (href) {
        return {
          ButtonComponent: 'a',
          ...props,
          disabled,
          href,
          variant,
          palette,
          ...getTarget(href),
          onClick,
        };
      }

      return {
        ButtonComponent: 'button',
        disabled,
        variant,
        palette,
        onClick,
        ...props,
      } as GetButtonPropsType;
    };

    const { ButtonComponent, ...extraProps } = getProps();
    const classes = cx([...getClasses(extraProps), className]);
    return (
      <ButtonComponent as={as} ref={ref} className={classes} {...extraProps} />
    );
  }
);

export default Button;
